import styled from 'styled-components';
import mountain from '../../../../../public/images/svg/mountain.svg'

const InfoSectionStyle = styled.section`
	.main {
		padding-top: var(--sp10x);
		margin-bottom: var(--sectionDistance);
		position: relative;
		overflow: hidden;
		background: radial-gradient(81.6% 81.6% at 50% 50%, #1A3352 0%, #0F1A29 100%);
	}
	.info-title {
		margin-bottom: var(--sp4x);
	}
	.editor-wrapper {
		p, p>* { 
			color: var(--white1000);
			line-height: 26px;
		}
		p:not(:first-child) {
			margin-top: 8px;
		}
	}
	.button-wrapper {
		margin-top: var(--sp8x);
		.primary {
			margin: auto;
			.link-item {
				border: 1px solid var(--royalBlue1000);
			}
		}
	}
	
	.blur {
		background-color: rgb(16, 27, 43, 80%);
		backdrop-filter: blur(27px);
		-webkit-backdrop-filter: blur(27px);
		display: flex;
		flex-direction: column;
		align-items: center;
		height: fit-content;
		padding: var(--sp10x) 0;
		&>.row {
		    width: 100%;
		}
	}

	.bg-mountain {
		background-image: url(${mountain.src});
		background-repeat: no-repeat;
		background-size: auto;
		background-position: top;
		height: 100vh;
		width: 100vw;
		position: absolute;
		top: 0;
		margin-top: 0;
	}

	.pin-spacer {
	    overflow: hidden;
	}

	.wrapper {
		width: 100%;
		min-width: 100%;
		height: 100%;
		position: relative;
		padding-top: 400px;
	}

	.items-wrapper {
		margin: var(--sp8x) 0;
		justify-content: center;
		max-width: 1360px;
		&>div:not(:last-child) {
			.item {
				border-right: 1px solid white;
			}
		}
		.item {
		    height: 100%;
			max-width: fit-content;
			padding: 0 var(--sp5x);
			p {
				max-width: var(--sp35x);
			}
			&-title {
				font-size: 32px;
				line-height: 1.2;
			}
			&-text {
				color: rgba(255,255,255,80%);
				font-family: var(--avenir);
				li {
					margin-left: 22px;
					margin-top: 4px;
					line-height: 24px;
				}
			}
			ul {
				list-style: decimal;
			}
		}
	}

	.container {
		width: 100%;
		padding-left: 0;
		padding-right: 0;
		padding-bottom: 0;
		display: flex;
    	flex-direction: column;
    	align-items: center;
	}

	/* //! 1920 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeL}) {
	}

	/* //! 1536 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeM}) {
	}

	/* //! 1366 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeS}) {
	}

	/* //! 1280 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSize}) {
	}

	/* //! 768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSizeS}) {
		.empty-col {
			display: none;
		}
		.blur {
			border-radius: var(--sp3x) var(--sp3x) 0 0;
			padding: var(--sp5x) var(--sp1x);
		}
		.wrapper {
			border-bottom: 0px;
			margin-top: 0px;
			.box {
				min-width: 100%;
				padding: 24px 16px;
				margin-bottom: 0;
			}
		}
		.button-wrapper {
			margin-top: var(--sp5x);
		}
		.items-wrapper {
			margin: var(--sp5x) 0;
			max-width: unset;
			&>div:not(:last-child) {
				.item {
					border-right: none;
					border-bottom: 1px solid white;
				}
			}
			.item {
			    padding: var(--sp3x) 0;
			}
			.item, .item>p {
				max-width: unset;
			}
		}
	}
	/* //! Mobile */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		.empty-col {
			display: none;
		}
		.blur {
			border-radius: var(--sp3x) var(--sp3x) 0 0;
			padding: var(--sp5x) var(--sp1x);
		}
		.wrapper {
			border-bottom: 0px;
			margin-top: 0px;
			.box {
				min-width: 100%;
				padding: 24px 16px;
				margin-bottom: 0;
			}
		}
		.button-wrapper {
			margin-top: var(--sp5x);
		}
		.items-wrapper {
			margin: var(--sp5x) 0;
			max-width: unset;
			&>div:not(:last-child) {
				.item {
					border-right: none;
					border-bottom: 1px solid white;
				}
			}
			.item {
			    padding: var(--sp3x) 0;
			}
			.item, .item>p {
				max-width: unset;
			}
		}
	}
`;
export default InfoSectionStyle;
