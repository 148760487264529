import styled from 'styled-components';

const EditorStyle = styled.div`
	--largeDistance: var(--sp5x);
	--smallDistance: var(--sp1x);
	--paddingInlineStart: 1.3em;

	h5 {
		font-size: var(--h5);
		font-family: var(--avenir);
		font-weight: 500;
		color: var(--royalBlue1000);
		line-height: var(--lineHeightM);

		&:not(:first-child) {
			margin-top: var(--largeDistance);
		}
	}

	h6 {
		font-size: var(--h6);
		font-family: var(--avenir);
		font-weight: 500;
		color: var(--royalBlue1000);
		line-height: var(--lineHeightM);

		&:not(:first-child) {
			margin-top: var(--largeDistance);
		}
	}

	p {
		font-size: var(--p5);
		font-family: var(--avenir);
		font-weight: 400;
		color: var(--royalBlue1000);
		line-height: var(--lineHeightL);

		&:not(:first-child) {
			margin-top: var(--largeDistance);
		}
	}

	ul {
		margin-top: var(--smallDistance);
		list-style-type: disc;
		padding-inline-start: var(--paddingInlineStart);

		li {
			font-size: var(--p5);
			font-family: var(--avenir);
			font-weight: 400;
			line-height: var(--lineHeightL);
			color: var(--royalBlue1000);
		}

		ul {
			margin-top: 0;
		}
	}

	i {
		font-size: var(--p5);
		line-height: var(--lineHeightL);
		color: var(--royalBlue1000);
	}

	strong {
		font-size: var(--p5);
		line-height: var(--lineHeightL);
		color: var(--royalBlue1000);
		font-family: var(--avenir);
		font-weight: 500;
	}

	ol {
		margin-block-start: 0;
		margin-block-end: 0;
		padding-inline-start: var(--paddingInlineStart);
		margin-top: var(--smallDistance);

		li {
			font-size: var(--p5);
			font-family: var(--avenir);
			font-weight: 400;
			line-height: var(--lineHeightL);
			color: var(--royalBlue1000);
		}

		ol {
			margin-top: 0;
		}
	}

	blockquote {
		border-left: 5px solid var(--royalBlue1000);
		font-size: var(--p5);
		line-height: var(--lineHeightL);
		font-family: var(--avenir);
		font-weight: 400;
		color: var(--royalBlue1000);
		margin-block-start: 0;
		margin-block-end: 0;
		margin-inline-start: 0;
		margin-inline-end: 0;
		margin-top: var(--smallDistance);

		p {
			margin-left: var(--smallDistance);
		}
	}

	a {
		font-family: var(--avenir);
		font-weight: 400;
		font-size: var(--p5);
		line-height: var(--lineHeightL);
		text-decoration: underline;
		color: var(--royalBlue1000);
		transition: all var(--trTime) ease-out;

		@media (hover: hover) {
			&:hover {
				text-decoration: none;
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeL}) {
		--largeDistance: var(--sp5x);
		--smallDistance: var(--sp1x);
		--paddingInlineStart: 1.2em;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeM}) {
		--largeDistance: var(--sp3-5x);
		--smallDistance: var(--sp1x);
		--paddingInlineStart: 1.2em;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeS}) {
		--largeDistance: var(--sp3x);
		--smallDistance: var(--sp1x);
		--paddingInlineStart: 1.2em;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSize}) {
		--largeDistance: var(--sp2-5x);
		--smallDistance: var(--sp1x);
		--paddingInlineStart: 1.2em;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSizeS}) {
		--largeDistance: var(--sp2-5x);
		--smallDistance: var(--sp1x);
		--paddingInlineStart: 1.2em;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--largeDistance: var(--sp2x);
		--smallDistance: var(--sp0-5x);
		--paddingInlineStart: 1.1em;
	}
`;

export default EditorStyle;
