'use client';

import { useRef, useLayoutEffect } from 'react';

import { gsap } from 'gsap';

import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
//* Style
import InfoSectionStyle from './style';

//* HOC's
import { withDataContext, withUIContext } from '@/context';

//* Components
import Text from '@/components/global/Text';
import Button from '@/components/global/Button';
import Container from '@/components/global/Container';
import Editor from '@/components/global/Editor';

const InfoSection = ({ title, description, second_description }) => {
	//! Global Data

	//! Refs
	const infoContainerRef = useRef([]);
	const infoTitleRef = useRef([]);
	const infoTextRef = useRef([]);
	const container = useRef([]);
	
	useLayoutEffect(() => {
		let headerHeight = window.innerWidth >= 768 ? 104 : 56;
		gsap.to('.bg-mountain', {
			scrollTrigger: {
				trigger: '.info-wrapper',
				start: `top +=${headerHeight}px`,
				end: `bottom bottom`,
				pin: '.bg-mountain',
				pinType: 'fixed',
			}
		})
	}, [])

	return (
		<InfoSectionStyle>
			<Container
				full
				isSection
				ref={infoContainerRef}
				className={'main info-section'}
			>
				<Container className='info-section-wrapper'>
					<div className='container row'>
						<Text
							ref={infoTitleRef}
							className={`col-10 col-t-12 info-title h4 font-argent white-1000 letter-spacing-h4 text-center`}>
							{title}
						</Text>
					</div>
					<div className='container row'>
						<div className={`col-10 col-t-12 text-center`}>
							<Editor data={description} />
						</div>
					</div>
					<div className='wrapper row content info-wrapper' ref={container}>
						<div className='bg-mountain'></div>
						<div className='blur'>
							<div className='row'>
								<div className='col-3 empty-col'></div>
								<Text
									className={`col-6 col-t-12 p p4 font-avenir-medium white-1000 text-center`}>
									{'Without access to this information, taking ownership will be impossible.In the end, you don’t have a doomsday envelope or an interactive safe box with self-renewal and proactive features to transfer these details to family members in case of an emergency.'}
								</Text>
								<div className='col-3 empty-col'></div>
							</div>
							<div className='row items-wrapper'>
								<div className='col-3 col-t-12'>
									<div className='item'>
										<Text
											className={'p letter-spacing-p1 item-title font-argent white-1000'}>
											MyHUB
										</Text>
										<br/>
										<Text
											className={'p p5 font-avenir-regular item-text'}>
											Proprietary servers hosted by global tier-4 providers.
											<br/><br/>
											This is the only place where information remains your property and is stored securely on your side.
										</Text>
									</div>
								</div>
								<div className='col-3 col-t-12'>
									<div className='item'>
										<Text
											className={'p letter-spacing-p1 item-title font-argent white-1000'}>
											Assets
										</Text>
										<br/>
										<Text
											className={'p p5 font-avenir-regular item-text'}>
											Keep records of 40 types of assets, including stocks, real estate, crypto, collectibles, and accounts, along with other important information.
										</Text>
									</div>
								</div>
								<div className='col-3 col-t-12'>
									<div className='item'>
										<Text
											className={'p letter-spacing-p1 item-title font-argent white-1000'}>
											Family
										</Text>
										<br/>
										<Text
											className={'p p5 font-avenir-regular item-text'}>
											Quickly specify who should Plan for the unexpected. 
											<br/>
											Predefine:
										</Text>
										<ul className='item-text'>
											<li>Who needs access to your asset information?</li>
											<li>What information should they see? </li>
											<li>Which assets are relevant?</li>
										</ul>
									</div>
								</div>
								<div className='col-3 col-t-12'>
									<div className='item'>
										<Text
											className={'p letter-spacing-p1 item-title font-argent white-1000'}>
											Algorithm
										</Text>
										<br/>
										<Text
											className={'p p5 font-avenir-regular item-text'}>
											Purely algorithmic information transfer eliminates human-factor risks. Not too soon—the family might be unprepared for the responsibility. Not too late—when execution becomes impossible. But just-in-time.
										</Text>
									</div>
								</div>
							</div>

							<div className='row'>
								<div className='col-3 empty-col'></div>
								<Text
									className={`col-6 col-t-12 p p4 font-avenir-regular white-1000 text-center`}>
									Owner.One was set up in response to the problems of a particular family with the information asymmetry issue described above. Read The True Story
								</Text>
								<div className='col-3 empty-col'></div>
							</div>

							<div className='row'>
								<div className='col-12'>
									<div className='button-wrapper'>
										<Button
											text='TRUE STORY'
											className='primary'
											url='/legend'
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Container>
			</Container>
		</InfoSectionStyle>
	);
};

export default withDataContext(withUIContext(InfoSection, ['winWidth']), ['getGlobalData']);
