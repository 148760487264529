//* Style
import EditorStyle from './style';

const Editor = ({ className, data }) => {
	return (
		<EditorStyle
			dangerouslySetInnerHTML={{ __html: data }}
			className={`editor-wrapper ${className || ''}`}
		/>
	);
};

export default Editor;
